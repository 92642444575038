<template>
	<v-container class="mt-4 ml-2" fluid>
	  <v-row  v-if="( getdatosUsuario.idpuesto == 18 && [568, 7, 161, 626, 382, 1312, 1314, 526 ].includes( getdatosUsuario.iderp ) ) || getdatosUsuario.idpuesto != 18">
	  	<v-col cols="12">
	  		<v-card class="shadowCard">
	  		  <v-card-title primary-title>
	  		    <span class="text-subtitle-1">Seguimiento Campañas Prospectos</span>
	  		    <v-spacer></v-spacer>

            <v-btn 
              class="mr-2" 
              small dark color="green"
              @click="exportExcel( filterSeguimiento  , 'SEGUIMIENTO_VAC')"
              tile
            >
              <v-icon small>mdi-microsoft-excel</v-icon>
            </v-btn>

            <v-btn
              color="orange"
              dark
              class="mr-2"
              @click="dialog = true"
              small
              tile
              v-if="[25,48,49,47,74,62,54,70,75,11].includes( getdatosUsuario.idpuesto )"
            >
              <v-icon left small>mdi-plus</v-icon>
              Agregar
            </v-btn>

            <v-btn
              color="primary"
              dark
              class="text-capitalize"
              @click="initialize()"
              small
              tile
            >
              <v-icon left small>mdi-refresh</v-icon>
              Actualizar
            </v-btn>

	  		  </v-card-title>
	  		  <v-card-text>
	  		  	<v-row justify="end">
              <v-col cols="12" md="3">
	  		  			<v-text-field label="Fecha Inicial" type="date" filled dense hide-details v-model="filtroFechaIni"></v-text-field>
	  		  		</v-col>
              <v-col cols="12" md="3">
	  		  			<v-text-field label="Fecha Final" type="date" filled dense hide-details v-model="filtroFechaFin"></v-text-field>
	  		  		</v-col>
              <v-spacer></v-spacer>
	  		  		<v-col cols="12" md="3">
                
	  		  			<v-text-field
	  		  			  name="name"
	  		  			  label="Buscar"
	  		  			  id="id"
	  		  			  filled
	  		  			  dense
	  		  			  hide-details
	  		  			  append-icon="mdi-magnify"
	  		  			  v-model="search"
	  		  			></v-text-field>
	  		  		</v-col>

              <v-col cols="12" >
                <v-radio-group
                  v-model="escuela"
                  row
                  hide-details
                >
                  <v-radio
                    label="INBI"
                    :value="1"
                  ></v-radio>
                  <v-radio
                    label="FAST"
                    :value="2"
                  ></v-radio>
                  <v-radio
                    label="AMBAS"
                    :value="3"
                  ></v-radio>
                </v-radio-group>
              </v-col>
	  		  	</v-row>
	  		    <v-row>
	  		    	<v-col cols="12">
							  <v-data-table
							    :headers="headers"
							    :items="filterSeguimiento"
							    class="elevation-0"
							    :search="search"
							    :mobile-breakpoint="100"
			            dense
							  >
							    <template v-slot:item.actions="{ item }">
							    	<v-btn 
							    		color="primary" 
							    		x-small
							    		@click="editItem(item)"
							    		class="mr-2"
							    	>
								      <v-icon small>mdi-magnify</v-icon>
							    	</v-btn>

							    	<v-btn 
							    		color="error" 
							    		x-small
                			v-if="[25,48,49,47,74,62,54,70,75,11].includes( getdatosUsuario.idpuesto )"
							    		@click="deleteItem(item)"
							    		class="mr-2"
							    	>
								      <v-icon small> mdi-delete</v-icon>
							    	</v-btn>
							    </template>

                  <template v-slot:item.panel_crm="{ item }">
                    <v-chip 
                      color="primary" 
                      small
                      @click="abrirDialogoFolios( item )"
                      class="mr-2"
                      v-if="[10000,10001].includes( item.id_plantel ) "
                    >
                      {{ item.panel_crm }}
                    </v-chip>

                    <div
                      v-else
                    >{{ item.panel_crm }}</div>
                  </template>

                  <template v-slot:item.campania="{ item }">
                    <v-chip 
                      small
                      @click="getdatosUsuario.idpuesto != 18 ? abrirClasificacion( item ) : ''"
                      class="mr-2"
                    >
                      {{ item.campania }}
                    </v-chip>
                  </template>


							    <template v-slot:no-data>
							      <v-btn
							        color="primary"
							        @click="initialize"
							        small

							      >
							        Actualizar
							      </v-btn>
							    </template>

							  </v-data-table>
	  		    	</v-col>
	  		    </v-row>
	  		  </v-card-text>
	  		</v-card>
	  	</v-col>
	  </v-row>

	  <!-- Dialogo de editar o agregar-->
	  <v-dialog
      v-model="dialog"
      max-width="600px"
      persistent
    >
      <v-card>
        <v-card-title>
          <span class="text-subtitle-1">{{ formTitle }}</span>
          <v-spacer></v-spacer>
          <span class="text-subtitle-1">
          	<strong>ID: {{ editedItem.idseguimiento_prospectos }}</strong>
          </span>
        </v-card-title>

        <v-card-text>
          <v-row>

          	<v-col cols="12" md="6">
              <v-autocomplete
                label="Sucursal"
                filled
                dense
                hide-details
                v-model="editedItem.id_plantel"
                :items="planteles"
                item-text="plantel"
                item-value="id_plantel"
                clearable
                hide-details
              ></v-autocomplete>
            </v-col>

            <v-col cols="12" md="6">
              <v-autocomplete
                :items="filterCursos"
                v-model="editedItem.id_curso"
                label="Curso"
                clearable
                filled
                dense
                item-text="curso"
                item-value="id"
                hide-details
              ></v-autocomplete>
            </v-col>

            <v-col cols="12">
              <v-text-field
                v-model="editedItem.campania"
                label="Campaña"
                filled
                hide-details
                dense
                v-if="editedIndex > -1 && [25,48,49,47,74,62,54,70,75,11].includes( getdatosUsuario.idpuesto )"
              ></v-text-field>
            </v-col>


            <v-col cols="12" md="6">
              <v-text-field
                v-model="editedItem.resultados_mkt"
                label="Resul. MKT"
                filled
                type="number"
                disabled
                hide-details
                dense
                v-if="editedIndex > -1 && [25,48,49,47,74,62,54,70,75,11].includes( getdatosUsuario.idpuesto )"
              ></v-text-field>
            </v-col>

            <v-col cols="12" v-if="editedIndex > -1">
              <v-row>
                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="fecha"
                    label="Fecha"
                    filled
                    hide-details
                    dense
                    type="date"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="3">
                  <v-text-field
                    v-model="resultado"
                    label="Resultado"
                    filled
                    hide-details
                    dense
                    type="number"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="3">
                  <v-btn small dark color="primary" @click="saveResultado()" class="my-2">Agregar</v-btn>
                </v-col>
              </v-row>
              <v-data-table
                :headers="headersMkt"
                :items="detalles"
                class="elevation-0 mt-4"
                :search="search"
                :mobile-breakpoint="100"
                dense
              >

                <template v-slot:item.actions="{ item }">
                  <v-btn 
                    color="error" 
                    x-small
                    @click="deleteItem2(item)"
                    class="mr-2"
                  >
                    <v-icon small> mdi-delete</v-icon>
                  </v-btn>
                </template>
              </v-data-table>
            </v-col>

            <v-col cols="12" md="6">
              <v-text-field
                v-model="editedItem.resultados_operaciones"
                label="Resul. Operaciones"
                filled
                type="number"
                hide-details
                dense
                v-if="editedIndex > -1 && [18,19,11].includes( getdatosUsuario.idpuesto )"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions>
          <v-btn
            color="black"
            tile
            dark
            small
            @click="close"
          >
            <v-icon small left>mdi-cancel</v-icon>
            Cancelar
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="green darken-1"
            class=""
            tile
            dark
            small
            @click="save"
          >
            <v-icon small left>mdi-content-save</v-icon>
            Guardar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Dialogo para poder ver los folios -->
    <!-- Dialogo de editar o agregar-->
    <v-dialog
      v-model="dialogFolio"
      max-width="600px"
      persistent
    >
      <v-card>
        <v-card-title>
          <span class="text-subtitle-1">Folios sin clasificar</span>
          <v-spacer></v-spacer>
        </v-card-title>

        <v-card-text>
          <v-data-table
            :headers="headersFolios"
            :items="folios"
            class="elevation-0 mt-4"
            :mobile-breakpoint="100"
            dense
          >
          </v-data-table>
        </v-card-text>

        <v-card-actions>
          <v-btn
            color="black"
            tile
            dark
            small
            @click="dialogFolio = false"
          >
            <v-icon small left>mdi-cancel</v-icon>
            Cancelar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Dialogo de editar o agregar-->
    <v-dialog
      v-model="dialogCampanias"
      max-width="600px"
      persistent
    >
      <v-card>
        <v-card-title>
          <span class="text-subtitle-1">Campañas</span>
          <v-spacer></v-spacer>
        </v-card-title>

        <v-card-text>
          <v-row>
            <v-col cols="12" md="9">
              <v-autocomplete
                :items="campanias"
                v-model="campania"
                label="Campaña"
                dense
                filled
                item-value="campaign_id"
                item-text="nombre_campana"
                class="ma-2"
                hide-details
                return-object
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" md="3">
              <v-btn small dark color="primary" @click="saveCampania()" class="my-2">Agregar</v-btn>
            </v-col>
          </v-row>

          <v-data-table
            :items="clasificaciones"
            :headers="headersClasif"
            class="elevation-0 mt-4"
            :mobile-breakpoint="100"
            dense
          >
            <template v-slot:item.actions="{ item }">
              <v-btn 
                color="error" 
                x-small
                @click="deleteItemCampania(item)"
                class="mr-2"
              >
                <v-icon small> mdi-delete</v-icon>
              </v-btn>
            </template>
          </v-data-table>
        </v-card-text>

        <v-card-actions>
          <v-btn
            color="black"
            tile
            dark
            small
            @click="dialogCampanias = false"
          >
            <v-icon small left>mdi-cancel</v-icon>
            Cancelar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Dialogo para eliminar -->
    <v-dialog v-model="dialogDelete" max-width="500px">
      <v-card>
      	<v-card-text>
	      	<v-row justify="space-around">
	      		<v-col cols="6">
	      			<v-card class="elevation-0" height="100%" style="display: table;">
		      			<div class="text-subtitle-1" style="display: table-cell; vertical-align: middle;">
		      				<strong>Eliminar</strong> ¿Estás seguro que deseas eliminar el ciclo?
			      			<v-card-actions class="pt-6">
			      				<!-- Cerrar dialogo de confirmación y abrir el dialogo de editar la clase -->
			      			  <v-btn color="error" text  class="mr-2" @click="closeDelete()">No, cancelar</v-btn>
			      			  <!-- Guardar la información  -->
			      			  <v-btn color="#5C5C5C"  dark class="elevation-6"  @click="deleteItemConfirm()">Si</v-btn>
			      			</v-card-actions>
		      			</div>
	      			</v-card>
	      		</v-col>
	      		<v-col cols="5">
	      			<v-img src="@/assets/borrar.png"></v-img>
	      		</v-col>
		      </v-row>
      	</v-card-text>
      </v-card>
    </v-dialog>

    <!-- Dialog de carga -->
    <Alerta               v-if="respuestaAlerta"             :parametros="parametros"/>
    <carga                v-if="cargar"/>

	</v-container>
</template>
<script>
import axios from 'axios';
	import { mapGetters } from 'vuex'
	// Componentes
  import Alerta                from '@/components/alertas/Alerta.vue'
  import carga                 from '@/components/alertas/carga.vue';

  import validarErrores        from '@/mixins/validarErrores'
  import funcionesExcel        from '@/mixins/funcionesExcel'

  export default {

    mixins: [ validarErrores, funcionesExcel ],

  	components:{
      Alerta,
      carga,
    },

    data: () => ({
    	// Alertas
    	parametros:{
        dialogError: false,
        mensaje: '',
        color: ''
      },

      respuestaAlerta:false,
      loader: true,
      cargar: false,

      dialog: false,
      dialogDelete: false,
      
      editedIndex: -1,

      editedItem: {
        idseguimiento_prospectos: 0,
				id_plantel: 0,
				id_curso: 0,
				id_coordinadora: 0,
				campania: '',
				resultados_mkt: 0,
				resultados_operaciones: 0,
				funciona: 0,
				deleted:0,
				fecha_creacion:null
      },

      defaultItem: {
        idseguimiento_prospectos: 0,
				id_plantel: 0,
				id_curso: 0,
				id_coordinadora: 0,
				campania: '',
				resultados_mkt: 0,
				resultados_operaciones: 0,
				funciona: 0,
				deleted:0,
				fecha_creacion:null
      },

      // Filtros: 
      incio:(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
			fin  :(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),

      // tabla
			search:'',
      page: 1,
      pageCount: 0,
      itemsPerPage: 10,
      salones: [],
      headers: [
        { text: 'ID'                   , value: 'idseguimiento_prospectos'  },
        { text: 'Plantel  '            , value: 'plantel'                  },
        { text: 'Curso'                , value: 'cursos_escuela'           },
        { text: 'Coordinadora'         , value: 'nombre_completo'          },
        { text: 'Campaña'              , value: 'campania'                 },
        { text: 'Res. MKT'             , value: 'resultado_mkt_2'           },
        { text: 'facebook'             , value: 'facebook'                 },
        { text: 'Res. Oper.'           , value: 'resultados_operaciones'   },
        { text: 'Panel CRM	'          , value: 'panel_crm'                },
        { text: 'Inscritos F'          , value: 'inscritos_fecha'          },
        { text: 'Inscritos C'          , value: 'inscritos_ciclo'          },
        { text: '¿funciona?'           , value: 'funciona'                 },
        { text: 'Actions'              , value: 'actions', sortable: false },
      ],

      prospectos: [],
      cursos: [
      	{ id: 1  , curso: 'TEENS'  , escuela: 1 },
      	{ id: 2  , curso: 'KIDS'   , escuela: 1 },
      	{ id: 3  , curso: 'ADULTOS', escuela: 1 },
      	{ id: 4  , curso: 'ADULTOS', escuela: 2 },
      ],
      planteles:[],
      filtroEstatus: 0,
      filtroFechaIni: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      filtroFechaFin: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),

      detalles:[],
      headersMkt: [
        { text: 'Fecha'                , value: 'fecha'                  },
        { text: 'Resultado'            , value: 'valor'                   },
        { text: 'Actions'              , value: 'actions', sortable: false },
      ],
      fecha: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      resultado: 0,


      folios:[],
      headersFolios: [
        { text: 'ID'        , value: 'usuario_asignado' },
        { text: 'Vendedora' , value: 'vendedora' },
        { text: 'Folios'    , value: 'folios'     },
      ],
      dialogFolio: false,
      escuela:3,


      clasificaciones:[],
      idseguimiento_prospectos: 0,
      headersClasif: [
        { text: 'ID'       , value: 'campaign_id'    },
        { text: 'Campaña'  , value: 'nombre_campana' },
        { text: 'Actions'  , value: 'actions'        }
      ],
      dialogCampanias: false,
      campanias:[],
      campania: null
    }),

    computed: {
    	...mapGetters('login',['getdatosUsuario']),
      formTitle () {
        return this.editedIndex === -1 ? 'Agregar seguimiento' : 'Editar seguimiento'
      },

      filterSeguimiento(){

        let data = this.prospectos 

        if( this.escuela == 1 ){
          data = data.filter( el => !el.plantel.match('FAST') )
        }

        if( this.escuela == 2 ){
          data = data.filter( el => el.plantel.match('FAST') )
        }

        return data
      },

      filterCursos( ){

      	if( this.editedItem.id_plantel ){

      		const existePlantel = this.planteles.find( el => el.id_plantel == this.editedItem.id_plantel )

      		if( existePlantel ){

      			if( existePlantel.plantel.match('FAST')){

      				return [
                { id: 4  , curso: 'ADULTOS', escuela: 2 },
				      	{ id: 5 , curso: 'SIN CLASIFICAR', escuela: 2 },
				      ]

      			}else{
      				return [
				      	{ id: 1  , curso: 'TEENS'  , escuela: 1 },
				      	{ id: 2  , curso: 'KIDS'   , escuela: 1 },
                { id: 3  , curso: 'ADULTOS', escuela: 1 },
				      	{ id: 6 , curso: 'SIN CLASIFICAR', escuela: 1 },
				      ]
      			}

      		}else{
      			return []
      		}


      	}
      }
    },

    watch: {
      dialog (val) {
        val || this.close()
      },
      dialogDelete (val) {
        val || this.closeDelete()
      },

    },

    async created () {

    	if( this.getdatosUsuario.idpuesto == 18 ){
    		this.headers = [
	        { text: 'ID'                   , value: 'idseguimiento_prospectos' },
	        { text: 'Plantel  '            , value: 'plantel'                  },
	        { text: 'Curso'                , value: 'cursos_escuela'           },
	        { text: 'Campaña'              , value: 'campania'                 },
          { text: 'Res. MKT'             , value: 'resultados_mkt'           },
	        { text: 'facebook'             , value: 'facebook'                 },
	        { text: 'Res. Oper.'           , value: 'resultados_operaciones'   },
	        { text: 'Panel CRM	'          , value: 'panel_crm'                },
          { text: 'Inscritos F'          , value: 'inscritos_fecha'          },
	        { text: 'Inscritos C'          , value: 'inscritos_ciclo'          },
	        { text: '¿funciona?'           , value: 'funciona'                 },
	        { text: 'Actions'              , value: 'actions', sortable: false },
	      ]
    	}

      await this.getCampanias()
      await this.initialize()
      await this.getPlanteles()
      await this.getTurnos()
    },

    methods: {

      getCampanias () {
        this.cargar = true
        this.campanias = []

        const payload = {
          since  : this.filtroFechaIni,
          until  : this.filtroFechaFin
        }

        return this.$http.post('datos.campanias.facebook',payload).then(response=>{
          this.campanias = response.data
          this.cargar      = false
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      initialize () {
      	this.cargar = true
        this.prospectos = []
        
        const payload = {
        	fechaini: this.filtroFechaIni,
					fechafin: this.filtroFechaFin
        }

        return this.$http.post('seguimiento.prospectos', payload ).then(response=>{

        	if( this.getdatosUsuario.iderp == 626 ){ 
        		this.prospectos = response.data.filter( el => [32,21,4,6].includes( el.id_plantel )) 
        	}else if( this.getdatosUsuario.iderp == 7 ){ 
			    	this.prospectos = response.data.filter( el => [29,24,1,23].includes( el.id_plantel )) 
			    }else if( this.getdatosUsuario.iderp == 526 ){ 
			    	this.prospectos = response.data.filter( el => [22,2,3].includes( el.id_plantel )) 
			    }else if( this.getdatosUsuario.iderp == 1314 ){ 
			    	this.prospectos = response.data.filter( el => [25,28].includes( el.id_plantel )) 
			    }else if( this.getdatosUsuario.iderp == 1312 ){ 
			    	this.prospectos = response.data.filter( el => [27,5,31,17].includes( el.id_plantel )) 
			    }else if( this.getdatosUsuario.iderp == 382 ){ 
			    	this.prospectos = response.data.filter( el => [16,7,11,9].includes( el.id_plantel )) 
			    }else if( this.getdatosUsuario.iderp == 161 ){ 
			    	this.prospectos = response.data.filter( el => [14,8,12,10].includes( el.id_plantel )) 
			    }else {
			    	this.prospectos = response.data
			    }


          for( const i in this.prospectos ){

            const { idscampanias } = this.prospectos[i]

            let filtro = this.campanias.filter( el => idscampanias.includes( el.campaign_id ) )

            let resultado = filtro.map(item => parseInt(item.resultados ) ).reduce((prev, curr) => prev + curr, 0 )

            console.log( idscampanias, filtro, resultado )


            let resultados_campanias = this.campanias.filter( el => idscampanias.includes( el.campaign_id ) ).map(item => parseInt(item.resultados ) ).reduce((prev, curr) => prev + curr, 0)

            this.prospectos[i]['facebook'] = resultados_campanias 

          }

          console.log( response.data[0] )

        	this.cargar      = false
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      getPlanteles () {
      	this.cargar = true
        this.planteles = []
        return this.$http.get('planteles.activos').then(response=>{
          this.planteles = response.data

          this.planteles.push({
            id_plantel: 10000,
            plantel   : 'SIN CLASIFICAR INBI',
            escuela   : 1
          })

          this.planteles.push({
            id_plantel: 10001,
            plantel   : 'SIN CLASIFICAR FAST',
            escuela   : 2
          })

        	this.cargar      = false
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      getTurnos( ){
        this.cargar = true
        this.turnos = []
        this.$http.get('seguimiento.turnos').then(response=>{
          this.turnos = response.data
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },


      editItem (item) {
        this.editedIndex = this.prospectos.indexOf(item)
        this.editedItem = Object.assign({}, item)

        let tipo = 2
        if( [71,72,64,68,69,76,77,63,31].includes( this.getdatosUsuario.idpuesto ) ){ tipo = 1 }

        let payload = {
          idseguimiento_prospectos: this.editedItem.idseguimiento_prospectos, 
          tipo
        }

        // Lo mandapos por el EP
        this.$http.post('seguimiento.prospectos.detalle', payload).then(response=>{
          this.detalles = response.data
          this.dialog = true
          this.cargar = false
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })

      },

      deleteItem (item) {
        this.editedIndex = this.prospectos.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialogDelete = true
      },

      deleteItemConfirm () {
      	this.cargar = true
        // Lo mandapos por el EP
        this.editedItem.deleted = 1

        this.$http.put('seguimiento.prospectos.update/' + this.editedItem.idseguimiento_prospectos, this.editedItem).then(response=>{
        	this.cargar = true
        	this.closeDelete()
        	this.initialize()
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })

      },

      close () {
        this.dialog = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      closeDelete () {
        this.dialogDelete = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      save () {
      	if(this.editedItem.horario == ''){
      		this.validarErrorDirecto('Favor de llenar todos los campos')
      	}
      	// Cargamos al usuario que hizo el cambio
	      this.cargar = true

        if (this.editedIndex > -1) {
	        // Lo mandapos por el EP
          this.$http.put('seguimiento.prospectos.update/' + this.editedItem.idseguimiento_prospectos, this.editedItem).then(response=>{
          	this.validarSuccess( response.data.message )
          	this.initialize()
        		this.close()
	        }).catch( error =>{
	          this.validarError( error.response.data.message )
	        }).finally( () => { this.cargar = false })

        } else {
	        // Lo mandapos por el EP
          this.$http.post('seguimiento.prospectos.add', this.editedItem).then(response=>{
          	this.initialize()
        		this.close()
	        	this.cargar = false
	        }).catch( error =>{
          	this.validarError( error.response.data.message )
        	}).finally( () => { this.cargar = false })
        }
      },

      saveResultado( ){

        let tipo = 2
        if( [71,72,64,68,69,76,77,63,31].includes( this.getdatosUsuario.idpuesto ) ){ tipo = 1 }

        let payload = {
          fecha     : this.fecha,
          valor     : this.resultado,
          rh_mkt    : tipo,
          id_usuario: this.getdatosUsuario.iderp,
          idseguimiento_prospectos: this.editedItem.idseguimiento_prospectos
        }

        let valor = Object.assign({}, this.editedItem )

        // Lo mandapos por el EP
        this.$http.post('seguimiento.prospectos.detalle.add', payload).then(response=>{
          this.resultado = 0
          this.close( )
          this.initialize( )
          this.cargar = false
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },


      abrirDialogoFolios( item ){

        this.folios = item.folios
        this.dialogFolio = true

      },

      abrirClasificacion( item ){

        this.idseguimiento_prospectos = item.idseguimiento_prospectos
        this.cargar = true
        // Consultar los seguimientos
        // Lo mandapos por el EP
        this.clasificaciones = []
        this.$http.get('seguimiento.campanias/' +  this.idseguimiento_prospectos).then(response=>{
          this.clasificaciones = response.data
          this.dialogCampanias = true
          this.cargar = false
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })

      },

      saveCampania( ){

        const payload = {
          idseguimiento_prospectos: this.idseguimiento_prospectos,
          campaign_id             : this.campania.campaign_id,
          nombre_campana          : this.campania.nombre_campana          
        }


        this.cargar = true
        // Consultar los seguimientos
        // Lo mandapos por el EP
        this.$http.post('seguimiento.campanias', payload ).then(response=>{
          this.campania = null
          this.abrirClasificacion( payload )
          this.dialogCampanias = true
          this.cargar = false
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })

      },

      deleteItemCampania( item ){
        const payload = {
          idseguimiento_prospectos: this.idseguimiento_prospectos,
        }
        this.cargar = true
        // Consultar los seguimientos
        // Lo mandapos por el EP
        item.deleted = 1 
        this.$http.put('seguimiento.campanias/' + item.idfacebook_campanias , item ).then(response=>{
          this.campania = null
          this.abrirClasificacion( payload )
          this.dialogCampanias = true
          this.cargar = false
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })

      },

    },
  }
</script>


